// 计划管理

import {getRequest,postRequest,getDownLoad ,urlTitle} from "@/utils/request";
import { post } from "jquery";
import qs from 'qs'
window.qs = qs;


/**
 * 停电计划
 */


// 分页搜索
export const powerPlan =(query) => {
  return getRequest(`${urlTitle}/power/plan/index`,query);
}
//新增停电计划
export const powerPlanSave=(query)=>{
  return postRequest(`${urlTitle}/power/plan/save`,query)
}
//修改停电计划
export const powerPlanUpdate=(query)=>{
  return postRequest(`${urlTitle}/power/plan/update`,query)
}
//删除停电计划
export const powerPlanRemove=(query)=>{
  return postRequest(`${urlTitle}/power/plan/remove/${query}`)
}

//修改计划中的状态
export const powerPlanUpdateById=(query)=>{
  return postRequest(`${urlTitle}/power/plan/updateById?id=${query.id}&status=${query.status}`)
}
// 获取场站列表
export const queryStationId=query=>{
  return getRequest(`${urlTitle}/station/info/getStationByCompanyId`,query)
}
//左侧公司列表
export const compConstruction =() => {
  return getRequest(`${urlTitle}/companyInfo/construction`,);
}
//导出
export const downLoadFileBlackout=query=>{
  return getDownLoad(`${urlTitle}/power/plan/export`,query)
}



/**
 * 培训计划
 */

//培训计划左侧分类
export const trainTree= ()=>{
  return getRequest(`${urlTitle}/training/program/list`)
}
//培训计划左侧分类新增
export const trainTreeAdd = query =>{
  return postRequest(`${urlTitle}/training/program/add`,query)
}
//培训计划左侧修改
export const trainTreeUpdate = query =>{
  return postRequest(`${urlTitle}/training/program/update`,query)
}
//培训计划左侧删除
export const trainTreeDelete = query =>{
  return postRequest(`${urlTitle}/training/program/delete/${query}`)
}



//培训计划列表查询
export const trainListQuery= query=>{
  return getRequest(`${urlTitle}/training/program/item/list`,query)
}
//上传文件
export const updateFileTrain = query =>{
  return postRequest(`${urlTitle}/training/program/item/file/upload`,query)
}
//新增list
export const trainListAdd = query =>{
  return postRequest(`${urlTitle}/training/program/item/add`,query)
}
//修改list
export const trainListUpdate = query =>{
  return postRequest(`${urlTitle}/training/program/item/update`,query)
}
//删除list
export const trainListDelete = query =>{
  return postRequest(`${urlTitle}/training/program/item/delete/${query}`)
}

//查询文件列表
export const queryFileList= query =>{
  return getRequest(`${urlTitle}/training/program/item/file/list`,query)
}
//下载文件
export const downLoadFileTrain= query =>{
  return getDownLoad(`${urlTitle}/sysfile/download`,query)
}
//删除文件
export const trainDialogFileDelete = query =>{
  return postRequest(`${urlTitle}/training/program/item/file/${query}`)
}

