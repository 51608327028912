<template>
  <div>
    <div class="content">
      <div class="contentL">
        <div class="contentTitle">组织结构</div>
        <!-- @click="handleSearch"  suffix-icon="el-icon-search" -->
        <el-input clearable class="inputValue" v-model="filterText" placeholder="请输入关键词搜索">
        </el-input>
        <div class="tree-container">
          <!-- <el-checkbox v-model="selectAll" @change="handleSelectAll" class="customSelect">{{ selectAllText
          }}</el-checkbox> -->
          <el-tree v-loading="loading" ref="tree" :default-expand-all="false" :show-checkbox="false" :data="treeData"
            :check-on-click-node="false
              " :props="defaultProps" node-key="id" :filter-node-method="filterNode" @node-click="handleCheck"></el-tree>
        </div>
      </div>
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">停电计划</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="场站名称: ">
                  <el-select clearable class="custom-select" filterable :popper-append-to-body="false"
                    v-model="formInline.stationId" placeholder="请选择">
                    <el-option v-for="item in stationList" :key="item.id"
                      :label="item.stationName + ' ' + item.stationNumber" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="计划名称：">
                  <el-input clearable v-model="formInline.name" placeholder="请输入关键词" class="custom-input"></el-input>
                </el-form-item>
               
                <!-- <el-form-item label="手机号：">
                  <el-input v-model="formInline.content" placeholder="请输入手机号" class="custom-input"></el-input>
                </el-form-item> -->
                <el-form-item label="计划开始时间：">
                  <el-date-picker v-model="formInline.startTime" :append-to-body="false" type="date"
                    value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> <span> ~
                  </span>
                  <el-date-picker v-model="formInline.endTime" :picker-options="pickerOptions" :append-to-body="false"
                    type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="计划状态">
                  <el-select clearable class="custom-select" :popper-append-to-body="false" v-model="formInline.status"
                    placeholder="请选择">
                    <el-option label="未开始" :value="1"></el-option>
                    <el-option label="执行中" :value="2"></el-option>
                    <el-option label="已终止" :value="3"></el-option>
                    <el-option label="已完成" :value="4"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item>
                </el-form-item>
              </el-form>
            </div>
            <div class="newView">
              <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>

              <el-button type="primary" @click="replyDialog('', 0)" icon="el-icon-plus"
                :class="isStringInArray('btnAddBlackOutPlanBlackOut') ? '' : 'btnShowAuthority'"
                class="custom-button">添加停电计划</el-button>
              <el-button type="primary" @click="openViewDialog('', 0)" icon="el-icon-download"
                :class="isStringInArray('btnExportBlackOut') ? '' : 'btnShowAuthority'" class="custom-button">导出</el-button>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
            :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" border :data="tableData" style="width: 100%;"
            class="custom-table">
            <div slot="empty">
              <div v-if="tableData.length == 0">
                <div class="empty-data-view">
                  <div class="tableText">
                    {{ dataLoading ? '' : '暂无数据' }}
                  </div>
                </div>
              </div>
            </div>
            <el-table-column align="center" label="序号" type="index" :index="getIndex" width="100">
            </el-table-column>
            <el-table-column align="center" prop="stationIdDesc" label="变配电站" width="200">
            </el-table-column>
            <el-table-column align="center" prop="stationNumber" label="场站编码" width="200">
            </el-table-column>
            <el-table-column align="center" prop="name" label="计划名称" width="200">
            </el-table-column>
            <el-table-column align="center" prop="duration" label="停电时长(h)" width="150">
            </el-table-column>
            <el-table-column align="center" prop="startTime" label="计划开始时间" width="200">
            </el-table-column>
            <el-table-column align="center" prop="endTime" label="计划结束时间" width="200">
            </el-table-column>
            <el-table-column align="center" prop="email4" label="状态" width="200">
              <template slot-scope="scope">
                <!-- <div class="" style='color:red' >

                  {{ scope.row.status == 1 ? '未开始' : scope.row.status == 2 ? '执行中' : '已终止' }}
                </div> -->
                <div v-show="scope.row.status == 1" style='color:yellow;'>未开始</div>
                <div v-show="scope.row.status == 4" style='color:green;'>已完成</div>
                <div v-show="scope.row.status == 2" style='color:blue;'>执行中</div>
                <div v-show="scope.row.status == 3" style='color:red;'>已终止</div>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="createByDesc" label="任务派发人" width="150">
            </el-table-column> -->
            <el-table-column align="center" prop="remark" label="备注" :fluid="true" min-width="300">
              <template slot-scope="scope">
                <template v-if="isOverflow(scope.row.remark)">
                  <el-tooltip :content="scope.row.remark" placement="top" :popper-class="'custom-tooltip'">
                    <div class="ellipsis">{{ scope.row.remark }}</div>
                  </el-tooltip>
                </template>
                <template v-else>
                  <div class="ellipsis">{{ scope.row.remark }}</div>
                </template>
              </template>

            </el-table-column>
            <el-table-column align="center" prop="data" fixed="right" label="操作" width="250">
              <template slot-scope="scope">
                <div class="" style="display: flex; justify-content: center;align-items: center;">
                  <div :class="isStringInArray('btnModifyBlackOut') ? '' : 'btnShowAuthority'">
                    <el-button v-show="scope.row.status == 1" @click="replyDialog(scope.row, 1)"
                      type="text">编辑</el-button><span v-show="scope.row.status == 1">|</span>
                  </div>
                  <div>
                    <el-button @click="replyDialog(scope.row, 2)" type="text">查看</el-button><span>|</span>
                  </div>
                  <div :class="isStringInArray('btnImplementBlackOut') ? '' : 'btnShowAuthority'">
                    <el-button v-show="scope.row.status == 1" @click="replyDialogOpen(scope.row, 2)"
                      type="text">执行</el-button><span v-show="scope.row.status == 1">|</span>
                  </div>
                  <div :class="isStringInArray('btnStopBlackOut') ? '' : 'btnShowAuthority'">
                    <el-button v-show="[1, 2].includes(scope.row.status)" @click="replyDialogOpen(scope.row, 3)"
                      type="text">终止</el-button><span v-show="[1, 2].includes(scope.row.status)">|</span>
                  </div>
                  <div :class="isStringInArray('btnCompleteBlackOut') ? '' : 'btnShowAuthority'">
                    <el-button v-show="[2].includes(scope.row.status)" @click="replyDialogOpen(scope.row, 4)"
                      type="text">完成</el-button><span v-show="[2].includes(scope.row.status)">|</span>
                  </div>
                  <div :class="isStringInArray('btnDeleteBlackOut') ? '' : 'btnShowAuthority'">
                    <el-button @click="openViewDialog(scope.row, 1)" type="text">删除</el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div class="btn" @click="startHome">首页</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
            layout="prev, pager, next, sizes" :prev-text="prevButtonText" :next-text="nextButtonText"
            prev-button-class="custom-prev-button" next-button-class="custom-next-button" :total="total">
          </el-pagination>
          <div class="btn endBtn" @click="endHome">尾页</div>
          <div class="paginationEnd">
            <div>跳</div>至
            <el-input size="mini" @change="paginationEnd" class="paginationEndIpt" @input="handleEdit"
              v-model.number="paginationEndIpt"></el-input>
            <div>页</div>
            <div class="btn confirm" @click="jumpPage">确定</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新建停电计划 -->
    <el-dialog @close="handleClose" :title="type == 0 ? '新增停电计划' : type == 1 ? '修改停电计划' : '查看'"
      :visible.sync="centerDialogVisible" width="35%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <!-- <el-form-item label="场站选择" prop="stationId">
          <el-select clearable :disabled="type == '2'" class="custom-select" :popper-append-to-body="false"
            v-model="ruleForm.stationId" placeholder="请选择场站">
            <el-option v-for="item in stationList" :key="item.id" :label="item.stationName" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item v-if="type != 1 && type != 0" label="变配电站" prop="stationId">
          <el-input placeholder="请选择变配电站" v-model="stationName" :readonly="true">
            <!-- <template slot="append">
                  <span @click="selectStation" class="appendSlot">选择配电站</span>
                </template> -->
          </el-input>
        </el-form-item>
        <el-form-item v-else label="变配电站" prop="stationId">
          <el-input placeholder="请选择变配电站" v-model="stationName" :readonly="true">
            <template slot="append">
              <span @click="selectStation" class="appendSlot">选择配电站</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="计划名称" prop="dictName">
          <el-input :disabled="type == '2'" placeholder="请输入计划名称" v-model.trim="ruleForm.name"
            class="my-custom"></el-input>
        </el-form-item>
        <el-form-item label="计划开始时间：" prop="startTime">
          <el-date-picker :disabled="type == '2'" @change="convertToDate" :picker-options="pickerOptionsStart"
            class="custom-picker" :append-to-body="false" v-model="ruleForm.startTime"
            value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择计划开始时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="计划结束时间：" prop="endTime">
          <el-date-picker :disabled="type == '2'" @change="convertToDate" class="custom-picker" :append-to-body="false"
            :picker-options="addPickerOptions" v-model="ruleForm.endTime" value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime" placeholder="请选择计划结束时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="停电时长(h)" prop="duration">
          <el-input :disabled="type == '2'" placeholder="请输入停电时长" v-model.trim="ruleForm.duration"
            class="my-custom"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="">
          <el-input :disabled="type == '2'" show-word-limit maxlength="200" type="textarea" class="my-custom" :rows="8"
            v-model.trim="ruleForm.remark"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer" v-if="type != 2">
        <!-- <div class="">取消</div>
          <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="addCenterDialog('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog :title="deleteDialogType == 0 ? '导出' : '删除'" :visible.sync="replyDialogVisible" width="20%">

      <div class="deleteCenter">
        <!-- 确定删除该条信息? -->
        {{ deleteDialogType == 0 ? '确定要下载该文件吗？' : '确定要删除计划吗？' }}
      </div>

      <span slot="footer" class="dialog-footer">

        <el-button class="dialogBtn" @click="replyDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" :loading="btnLoading" @click="deleteDialog">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="formId.status == 2 ? '执行' : formId.status == 3 ? '终止' : '完成'" :visible.sync="replyDialogId1"
      width="20%">

      <div class="deleteCenter">
        {{ formId.status == 2 ? '确定要执行该停电计划？' : formId.status == 3 ? '确定要终止该停电计划？' : '确定完成该停电计划' }}
      </div>

      <span slot="footer" class="dialog-footer">

        <el-button class="dialogBtn" @click="replyDialogId1 = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="modifyDialogIdConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 变配电站选择 -->
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationNameDialog"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>

<script>
import { powerPlan, powerPlanSave, powerPlanUpdate, powerPlanRemove, powerPlanUpdateById, compConstruction, downLoadFileBlackout, queryStationId } from '@/api/planAdministration.js'
// import demo from "./demo.vue"
import stationDialog from "./compoents/stationDialog.vue";
export default {
  name: 'Administration',
  components: { stationDialog },
  data() {
    return {
      replyDialogId1: false,
      formInline: {
        name: '',
        startTime: null,
        status: '',
        endTime: null,
        stationId: null,
        companyInfoId: '',
      },
      formId: {
        id: '',
        status: 1
      },
      tableData: [],
      deleteDialogType: 0,
      centerDialogVisible: false,
      replyDialogVisible: false,
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: '',
      pageSize: 10,
      currentPage: 1,
      total: 40,
      textarea: '',

      treeData: [

      ],
      checkedNodes: [],
      selectAll: false,
      dialogStatus: false,
      stationId: 0,
      stationName: '',
      selectAllText: '全选',
      treeTitle: [],
      treeText: '',
      selectContent: '',
      filterText: '',
      defaultProps: {
        children: 'companyInfoList',
        id: 'id',
        label: 'companyName'
      },
      type: 0,
      tableId: '',
      ruleForm: {
        name: '',
        startTime: '',
        endTime: '',
        duration: '',
        stationId: null,
        remark: '',

      },
      stationList: [],
      dataLoading: false,
      btnLoading: false,
      loading: false,
      rules: {
        name: [
          { required: true, message: '请输入文件类型', trigger: 'change' },
        ],
        startTime: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        endTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' },
        ],
        duration: [
          { required: true, message: '请输入停电时长', trigger: 'change' },
        ],
        stationId: [
          { required: true, message: '请选择场站', trigger: 'change' },
        ],

      },
      pickerOptionsStart: {
        disabledDate(time) {

          return time.getTime() < Date.now() - 86400000;
        }
      },
      authorityListBtn: [],

    };
  },
  // components:{demo},

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'

    // this.getStationName()
    this.getTreeList()
    this.authorityListBtn = sessionStorage.getItem('roleInfo')

  },

  created() {
    this.$store.commit('increment', '计划管理')
    this.$store.commit('selectChild', '停电计划')
    this.$store.commit('selectChildren', '')
  },
  computed: {


    pickerOptions() {
      const self = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(self.formInline.startTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        }
      };
    },
    addPickerOptions() {
      const self = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(self.ruleForm.startTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        }
      };
    }
  },
  watch: {
    filterText(val) {
      console.log('11111111111111', val);
      this.$refs.tree.filter(val);
    },
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "currentPage": {
      // 执行方法
      handler(newValue, oldValue) {
        this.paginationEndIpt = newValue;
      },
      immediate: true, // 第一次改变就执行
    },

  },

  methods: {
    //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    filterNode(value, data) {
      // console.log(value,data);
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    getTreeList() {
      this.loading = true
      compConstruction().then(res => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          // this.treeData=res.data
          this.treeData = res.data
          this.loading = false
          // this.formInline.companyInfoId = res.data[0].id
          this.gatStationList()
          // this.$nextTick(() => {
          //   this.$refs.tree.setCurrentKey(this.treeData[0].id); // 默认选中节点第一个
          // });
          this.getList()
        }
      })
    },


    getStationNameDialog(value) {
      if (value.id) {
        this.ruleForm.stationId = value.id
        // this.taskForm.stationId = value.id;
        this.stationName = value.stationName;
      }
      this.dialogStatus = false;
    },
    changeDialog(value) {
      this.stationId = 0

      this.dialogStatus = value;
    },
    selectStation() {
      if (this.type != 2) {
        this.dialogStatus = true;
      }
    },
    isOverflow(content) {
      if (content) {
        // const lineHeight = 16;
        const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
        // console.log("====>",numLines);
        return numLines >= 3;
      }
    },
    //获取停电时长
    convertToDate() {
      if (this.ruleForm.startTime) {
        const startTime = new Date(this.ruleForm.startTime).getTime();
        const timestamp = Date.now();
        if (startTime < timestamp) {
          this.ruleForm.startTime = null
          this.$message({
            message: '计划开始时间不得小于当前时间',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
        }
      }
      if (this.ruleForm.startTime && this.ruleForm.endTime) {

        const startTime = new Date(this.ruleForm.startTime).getTime();
        const endTime = new Date(this.ruleForm.endTime).getTime();
        const duration = (endTime - startTime) / (1000 * 60 * 60); // 时间差转换成小时
        this.ruleForm.duration = Number(duration.toFixed(1)); // 保留一位小数
        console.log(startTime, endTime);
        if (startTime == endTime) {
          this.ruleForm.endTime = null
          this.$message({
            message: '计划开始时间与计划结束时间不能相同',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
          this.ruleForm.endTime = null
        } else if (startTime > endTime) {
          // this.ruleForm.endTime=null
          this.$message({
            message: '计划开始时间不能大于计划结束时间',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
          this.addForm.endTime = null
        }
      }
    },

    //序号
    getIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },

    // getStationName() {
    //   powerPlanStationName().then(res => {
    //     this.stationList = res.data
    //   })

    // },
    //关闭弹窗
    handleClose() {
      this.ruleForm = this.$options.data().ruleForm
      this.$refs.ruleForm.clearValidate();
      this.stationId = 0
      this.stationName = ''
      console.log("1111111111111");
    },
    //修改计划状态确定
    modifyDialogIdConfirm() {
      powerPlanUpdateById(this.formId).then(res => {
        if (res.code == 200) {
          this.replyDialogId1 = false
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
          this.getList()
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })

    },
    //修改计划状态
    replyDialogOpen(el, type) {
      this.formId.id = el.id
      this.formId.status = type
      this.replyDialogId1 = true

    },
    //删除-导出 
    deleteDialog() {
      //删除
      if (this.deleteDialogType == 1) {
        powerPlanRemove(this.tableId).then(res => {
          if (res.code == 200) {
            this.replyDialogVisible = false
            this.$message({
              message: res.message,
              type: 'success',
              duration: 3000,
              customClass: "messageText",
            });
            this.getList()
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              duration: 3000,
              customClass: "messageText",
            });
          }

        })

      }
      if (this.deleteDialogType == 0) {
        this.btnLoading = true

        downLoadFileBlackout(this.formInline).then(res => {
          const link = document.createElement('a');
          // const filename = res.headers['Content-Disposition'].split('filename=')[1]; // 获取文件名称
          link.href = URL.createObjectURL(new Blob([res]));
          link.download = decodeURIComponent('停电计划.xlsx'); // 解码文件名称，避免名称中含有中文时乱码
          link.click();
          this.replyDialogVisible = false
          this.btnLoading = false
        })

      }

    },
    //新增/修改----停电计划
    addCenterDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          let func = this.type == 0 ? powerPlanSave(this.ruleForm) : powerPlanUpdate(this.ruleForm)
          func.then(res => {
            console.log(res);
            if (res.code == 200) {
              this.centerDialogVisible = false
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.getList()
              this.ruleForm = this.$options.data().ruleForm
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    // tree搜索
    handleSearch() {

    },
    gatStationList() {
      queryStationId({ companyId: this.formInline.companyInfoId }).then(res => {
        this.stationList = res.data
      })

    },
    handleCheck(checkedNodes, node) {

      this.checkedNodes = checkedNodes;
      // this.formInline.stationId = checkedNodes.id
      // if(this.formInline.companyInfoId == checkedNodes.id){
      //   console.log("111111111111");
      //   this.formInline.companyInfoId=null
      //   }
      // else{
      //   console.log(2222);
      //   this.formInline.companyInfoId = checkedNodes.id
      //     }
      this.$nextTick(() => {
        const currentNode = this.$refs.tree.$el.querySelector('.is-current');
        if (this.formInline.companyInfoId == checkedNodes.id) {
          console.log("11111111");
          if (currentNode) {
            console.log("3333333");
            currentNode.classList.remove('is-current');
            this.formInline.companyInfoId = ''
            this.gatStationList()
            // this.updateSelectAllState();
            console.log(this.checkedNodes);
            this.getList()
          }    
        } else {
          console.log("2222222222222", node.$el);
          this.formInline.companyInfoId = checkedNodes.id
          console.log("444444444");
          this.gatStationList()
          // this.updateSelectAllState();
          console.log(this.checkedNodes);
          this.getList()
          // this.$refs.tree.$el.querySelector('.el-tree-node').classList.add('is-current');
        }

      });

    },
    //列表查询
    getList() {
      this.dataLoading = true

      let obj = {
        ...this.formInline,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      if (obj.stationId) {
        obj.companyInfoId = null
      }
      powerPlan(obj).then(res => {
        this.dataLoading = false
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data
          this.total = res.total

        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    //查看
    replyDialog(el, type) {
      this.type = type
      console.log(el);
      this.stationName = el.stationIdDesc
      // this.TableId=type===1? el.id:''

      if (type == 1 || type == 2) {
        this.ruleForm = Object.assign({}, el);
      }
      this.centerDialogVisible = true
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();

      });

    },
    //查询列表
    onSubmit() {
      console.log('submit!');
      this.currentPage = 1
      this.getList()
    },

    //打开意见弹窗
    openViewDialog(el, type) {
      console.log(el);
      this.tableId = el.id
      this.replyDialogVisible = true
      this.deleteDialogType = type

    },
    //分页相关function
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      console.log(val);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1
      this.getList()

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
      this.getList()
    },
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.paginationEndIpt = value
      // console.log('value===>',value);
    },
    //页数输入时
    paginationEnd(val) {
      // console.log('11111',val);
      const lastPage = Math.ceil(this.total / this.pageSize);
      if (this.paginationEndIpt < 1) {
        this.paginationEndIpt = 1
      } else {
        this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
      }
    },
    //跳转
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      this.getList()
      // this.paginationEndIpt=''
    },
    //点击父节点
    titleTree(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }

    },
    // 点击子节点
    treeContent(item, content) {
      console.log(item, content);
      this.treeText = item.label

      this.selectContent = content.label
    }


  },
};
</script>
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  min-height: 781px;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 240px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}



.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 14px 26px 23px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  background: #05153A;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
  font-family: "Microsoft YaHei", Arial, sans-serif;
}

.custom-input-textarea ::placeholder {
  color: #6180a8;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183F !important;
  color: #FFC74A;
}


::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentTitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  /* width: 200px; */
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.custom-select>>>.el-select-dropdown {
  background: #041C4C !important;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041C4C !important;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

::v-deep .el-popper[x-placement^=bottom] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041C4C;
}

.el-select {
  width: 100%;
}

.custom-picker {
  width: 100%;
  /* background: #05153A; */
}

.custom-picker>>>.el-input__inner {
  background: #071734;

}

.my-custom>>>.el-input__inner {
  background: #071734;
}

.my-custom ::placeholder {
  color: #6180a8;
}

.my-custom>>>.el-textarea__inner {
  background: #071734;
  /* background: #051536; */
}

.el-textarea>>>.el-input__count {
  background: #071734;
}

/*时间日期选择器默认样式修改 */
::v-deep .el-time-panel {
  background: #041C4C;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409EFF;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041C4C;
}

::v-deep .el-picker-panel__footer .el-button--text {
  display: none;
}

::v-deep .is-plain {
  background: #176AD2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041C4C;
}

::v-deep .is-plain:hover {
  background: #176AD2;
  border: 0;
  color: #fff;
}

::v-deep .custom-select>>>[data-v-375fb726] .el-input.el-input--suffix {
  background: #071734;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: #071734;
  border-color: #09295b;
}

::v-deep .el-textarea.is-disabled .el-textarea__inner {
  background: #071734;
  border-color: #09295b;
}

::v-deep .el-loading-mask {
  background: #03183F;
}

/* ::v-deep .el-table__loading-text {
  display: block;
}

::v-deep .el-loading-parent--relative .el-loading-mask {
  display: none; 
} */
::v-deep .el-tree-node__label {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style>
.custom-tooltip {
  max-width: 300px;
  /* 设置最大宽度 */
  line-height: 20px;
}
</style>
